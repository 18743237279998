import { useState } from "react";
import "./styles.less";
import { ArrowIcon } from "shared-components/Components/CustomIcons/ArrowIcon/web";
import { CloseButton } from "shared-components/Components/CustomIcons/CloseButton/web";

type BannerHeaderProps = {
	data:any,
	isFromAr?:boolean
}

export const BannerHeader = ({ data, isFromAr = false }:BannerHeaderProps) => {
	const [isClosed, setIsClosed] = useState<boolean>(false)

	const closeBanner = () => {
		setIsClosed(true)
		localStorage.setItem("showBannerFlotante", "false");
	}

	return isClosed || localStorage.getItem("showBannerFlotante")==="false" ?
	<></>
	:
	(
		<>
			<div className="banner-on-header">
				<button className="close-banner-button" onClick={closeBanner}>
					<CloseButton width="10" />
				</button>

				{isFromAr ? (
					<a
						id="banner-header"
						href="https://www.infocasas.com.uy/proyectos/argentina-invierte/amp"
						target="_blank"
					>
						<div className="argento-preheader">
							<div className="contenedor">
								<div className="argento-preheader--image" id="argento-preheader--image"></div>
							</div>
						</div>
					</a>
				) : (
					<a
					id="banner-header"
					href={data.link}
					target="_blank"
					style={{
						backgroundColor: data.html ? "#fff" : data.background_color,
						color: data.text_color,
					}}>
					
					{data && data.html ? (
						<iframe src={data.html} /> // 70px x 100% desktop y 92px x 100% mobile
					) : (
						<div className="contenedor">
							{/* 700 x 70 */}
							<img src={data.image} className="banner" />
							<div
								className="right-content"
								style={{
									backgroundColor: data.background_color,
									color: data.text_color,
								}}>
								<div className="texto">
									<div
										className="top"
										style={{ color: data.text_color }}>
										{data.title}
									</div>
									{/* 50 chars */}
									<div
										className="bottom"
										style={{ color: data.text_color }}>
										{data.text}
									</div>
								</div>
								<div
									className="btn desktop"
									style={{
										backgroundColor: data.button_background_color,
										color: data.button_text_color,
									}}>
									{data.button_text}
								</div>
								<div
									className="btn mobile"
									style={{
										backgroundColor: data.button_background_color,
										color: data.button_text_color,
									}}>
									<ArrowIcon />
								</div>
							</div>
						</div>
					)}
				</a>
				)}
			</div>
			<style jsx>{`
				#banner-header:hover .btn {
					background-color: ${data ? data.button_text_color : ""} !important;
					color: ${data ? data.button_background_color : ""} !important;
				}

				.argento-preheader {
					width: 100%;
					height: 100%;
					background: #d77100;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					overflow: hidden;
					cursor: pointer;
				}

				.argento-preheader--image {
					width: 100%;
					height: 100%;
					background-image: url('https://cdn1.infocasas.com.uy/web/66d20814e1d96_1920.png');
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
				  }

				  @media (min-width: 1199px) and (max-width: 1334px) {
					.argento-preheader--image {
					  background-size: cover;
					}
				  }
			
				  @media (min-width: 981px) and (max-width: 1013px) {
					.argento-preheader--image {
					  background-size: cover;
					}
				  }
			
				  @media (min-width: 668px) and (max-width: 756px) {
					.argento-preheader--image {
					  background-size: cover;
					}
				  }
			
				  @media screen and (max-width: 380px) {
					.argento-preheader--image {
					  background-size: cover;
					}
				  }
			
				  @media (min-width: 1001px) and (max-width: 1280px) {
					.argento-preheader--image {
					  background-image: url('https://cdn1.infocasas.com.uy/web/66d20be850122_1200.png');
					}
				  }
			
				  @media (min-width: 669px) and (max-width: 1000px) {
					.argento-preheader--image {
					  background-image: url('https://cdn1.infocasas.com.uy/web/66d20be84fbd1_980.png');
					}
				  }    
			
				  @media screen and (max-width: 668px) {
					.argento-preheader--image {
					  background-image: url('https://cdn1.infocasas.com.uy/web/66d20be7d31de_425.png');
					}
				  }
			`}</style>
		</>
	);
};

